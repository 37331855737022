export function rotateOnScroll() {

	$(window).scroll(function() {
		// var slide = $(window).scrollTop() / 100 % Math.abs(100);
		// $('#roor-item-1 .slide-right,#release-item-1 .slide-right,.publishing-page .slide-right').css({ transform: 'translateX(' + slide + '%)' });

		var theta = $(window).scrollTop() / 7000 % Math.PI;
		$('#roor-item-1 .rotate-right').css({ transform: 'rotate(' + theta + 'rad)' });



		// var slide = $(window).scrollTop() / 200 % Math.abs(100);
		// $('#roor-item-2 .slide-right, .exotic-seed__content .slide-right,#release-item-2 .slide-right').css({ transform: 'translateX(' + slide + '%)' });

		var theta = $(window).scrollTop() / 9000 % Math.PI;
		$('#roor-item-2 .rotate-right').css({ transform: 'rotate(' + theta + 'rad)' });
	



		// var slide = $(window).scrollTop() / 500 % Math.abs(100);
		// $('#roor-item-3 .slide-right,#release-item-3 .slide-right,#release-item-4 .slide-right,#release-item-5 .slide-right,#release-item-6 .slide-right#release-item-7 .slide-right').css({ transform: 'translateX(' + slide + '%)' });

		var theta = $(window).scrollTop() / 10000 % Math.PI;
		$('#roor-item-3 .rotate-right').css({ transform: 'rotate(' + theta + 'rad)' });
			


		// var slide = $(window).scrollTop() / 400 % Math.abs(100);
		// $('#roor-item-4 .slide-right').css({ transform: 'translateX(' + slide + '%)' });

		var theta = $(window).scrollTop() / 12000 % Math.PI;
		$('#roor-item-4 .rotate-right').css({ transform: 'rotate(' + theta + 'rad)' });

	});

};


