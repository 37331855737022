export function slider() {

    $('.intro-slider').slick({
		fade: true,
		infinite: true,
		speed: 200,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: $('.intro-slider__prev'),
		nextArrow: $('.intro-slider__next')
	});

	$('.publishing-slider').slick({
		infinite: true,
		speed: 300,
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: $('.publishing-slider-prev'),
		nextArrow: $('.publishing-slider-next'),
		responsive: [
		    {
		      breakpoint: 1300,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 1
		      }
		    },
		    {
		      breakpoint: 600,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
		    }
		]
	});
	
};
