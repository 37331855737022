//////libs
//jQuery
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

//slick
import 'slick-carousel';
import 'slick-carousel-no-fonts';

//cookie banner
import {cookieBanner} from './scripts/libs/cookieBanner';
//import {inView} from './scripts/libs/inview';

//import Rellax from 'rellax';


//projectScripts
import {menu} from './scripts/projectScripts/menu';
import {slider} from './scripts/projectScripts/slider';
import {cookie} from './scripts/projectScripts/cookie';
import {videoList} from './scripts/projectScripts/videoList';
import {rotateOnScroll} from './scripts/projectScripts/rotateOnScroll';


//libs
cookieBanner();
//inView();

//projectScripts
menu();
cookie();
videoList();
rotateOnScroll();
slider();
