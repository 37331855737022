export function videoList() {
    
	$(".video-page__background li:first").addClass("video-page__background__img--active");

    $(function() {
	    var index = 0;
	    $('.video-page__list li').hover(function() {
	        $('.video-page__background__img').eq($(this).index()).addClass("video-page__background__img--active");
	        $('.video-page__background__img').eq($(this).index()).siblings("li").removeClass("video-page__background__img--active");
	    }, function() {
	        $('.video-page__background__img').eq($(this).index()).removeClass("video-page__background__img--active");
	        $('.video-page__background__img').eq(index).addClass("video-page__background__img--active");
	    });

	    $('.video-page__list li').click(function(){
	        index = $(this).index();
	        var element = $('.video-page__background__img').eq($(this).index());
	        element.addClass("video-page__background__img--active");
	        element.siblings('li').removeClass("video-page__background__img--active");
	    });
	});

};